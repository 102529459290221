import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Helmet from 'react-helmet'
import Img from "gatsby-image"


export default class Index extends React.Component {
  componentDidMount(){
    document.getElementById('lang-switch').href = 'https://e-multicontent.pl/kariera/'
  }

  render() {
    const { data } = this.props
    return (
      <>
      <Helmet titleTemplate="Join the team! | e-multicontent.com">
        <title>Join the team! | e-multicontent.com</title>
        <meta name="description" content={'Do you think we are looking for you? Get in touch with us via the contact form! ✔️'}/>
        <meta name='image' content={'https://e-multicontent.com/img/meta/kariera-meta.jpg'} />
        <meta name='keywords' content={'work, copywriting, copywriters, marketing, copywriting agency, copywriter team, work, business, content, content, translations, SEO, blog, texts, articles, graphics, video, websites, online stores, marketplace, e-commerce'} />
        <meta property="og:description" content={'Do you think we are looking for you? Get in touch with us via the contact form! ✔️'}/>
        <meta property='og:title' content={'Join the team! | e-multicontent.com'} />
        <meta property='og:image' content={'https://e-multicontent.com/img/meta/kariera-meta.jpg'} />
        <meta property="og:url" content={'https://e-multicontent.com/career/'}/>
        <link rel="alternate" href="https://e-multicontent.pl/kariera/" hrefLang="pl-pl" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="e-multicontent.com" />
        <meta property="twitter:url" content="https://e-multicontent.com/career/" />
        <meta name="twitter:title" content="Join the team! | e-multicontent.com" />
        <meta name="twitter:description" content="Do you think we are looking for you? Get in touch with us via the contact form! ✔️" />
        <meta name="twitter:image" content="https://e-multicontent.com/img/apple-touch-icon.png" />

        <script type='application/ld+json'>
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [{
            "@type": "ListItem",
            "position": 1,
            "name": "Home",
            "item": "https://e-multicontent.com"
          },{
            "@type": "ListItem",
            "position": 2,
            "name": "Career",
            "item": "https://e-multicontent.com/career/"
          }]
        })}
        </script>
      </Helmet>
      <Layout style={{backgroundColor:'rgba(255,255,255,0.0)'}}>
      <nav className="breadcrumbs-nav section">
          <Link className="inline-link" to="/">Home</Link>&nbsp;&gt;&nbsp;<span style={{fontWeight:'600',color:'white'}}><b>Career</b></span>
      </nav>
      <div>
      <Img fluid={data.file.childImageSharp.fluid}
      loading={'eager'}
      className="career-image"
      alt="Kariera w e-multicontent - copywriting, tłumaczenia, seo, strony internetowe, e-commerce, marketplace"
      style={{
        marginTop:'0px',
        width: '100%',
        position: 'absolute',
        minHeight: '900px',
        zIndex: '-1',
      }} />
      <div className="columns" style={{padding:'5%',color:'white'}}>
        <div className="column c-col" style={{marginTop:'5%',padding:'2%',backgroundColor:'rgba(0,0,0,.6)',borderRadius:'25px',height:'min-content'}}>

        <h1 style={{color:'#36b3d2'}} className="title"><b>Join the team!</b></h1>
        <h2  className="subtitle"><strong style={{color:'white'}}>Copywriting vacancy</strong></h2>
        <p style={{color:'white'}}>Each week brings new challenges and new projects in our agency. That is why we are constantly looking for people who could remotely support us in their implementation. We have clients from all over the world and currently create texts in several dozen foreign languages, including English, German, Romanian, Hungarian, French, Slovenian or Slovak.</p>
        <br></br>
        <h2 style={{color:'#36b3d2'}} className="title"><b>Who are we looking for?</b></h2>
        <h3  className="subtitle"><strong style={{color:'white'}}>Copywriter job Wrocław</strong></h3>
        <h4 style={{color:'#36b3d2'}} className="subtitle"><b>People who:</b></h4>
        <ul className="subtitle" style={{listStyle: 'disc',color:'white',paddingLeft:'5%',paddingTop:'2%'}}>
        <li>Have a perfect command, both spoken and written, of any foreign language, in short <strong style={{color:'white'}}>native-speakers, copywriters</strong>.</li>
        <li>Have a perfect command of Polish and who excel in written word, namely <strong style={{color:'white'}}>copywriters creating content in Polish</strong>.</li>
        <li>Know the basics of <strong style={{color:'white'}}>marketing communication and SEO</strong>.</li>
        <li>Are capable of working under <strong style={{color:'white'}}>tight deadlines</strong>.</li>
        </ul>

        <br></br>


        </div>


        <div className="column" style={{margin:'5% 1% 0% 1%',padding:'2%',textAlign:'center',paddingBottom:'0px',backgroundColor:'rgba(0,0,0,.6)',borderRadius:'25px'}}>
          <div style={{display:'flex',alignItems:'center',justifyContent:'center',flexWrap:'wrap',height:'100%'}}>
            <div>
            <img src="/img/thank-you-upl.svg" height="186px" width="200px" alt="formularz rekrutacyjny" />
            <br /><br />
            <h2 className="subtitle" style={{color:'white'}}>Fill out our recruitment form:</h2>
            <p style={{color:'white'}} className="subtitle">Do you think you are the right candidate? <br></br> Get in touch with us via the <a href="/contact-us/" target="_blank" rel="noopener noreferrer">recruitment form</a>!</p>
            <br /><br />
            <a target="_blank" style={{fontSize:'1.5em'}} className="btn" rel="noopener noreferrer" href="/contact-us/">Contact us <img width="15px" height="22px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="recruitment form Copywriting agency - translations and texts, SEO for websites, e-commerce and marketplace" src='/img/angle-right.svg'/></a>
            <br /><br />
            </div>
          </div>
        </div>


      </div>

      </div>

      <section className="section" style={{backgroundColor:'rgba(255,255,255,0.0)',width:'100%'}}>
      <div style={{marginTop:'0px',marginBottom:'0px'}} align="center">
      <br></br><br></br>
      <p style={{color:'#222222'}}><b>Would you rather contact us via email?</b></p>
      <br></br>
      <p><a href="mailto:info@multicontent.com" rel="noopener noreferrer" className="btn" target="_blank">info@multicontent.com</a></p>
      <br></br>

      <p style={{color:'#222222'}}><b>Would you prefer to give us a call?</b></p>
      <br></br>
      <p><a href="tel:+48713074517" rel="noopener noreferrer" className="btn" target="_blank">+48 71 307 45 17</a></p>
      <br></br>




      <br></br>


      <h3 style={{color:'#29b1ff'}}>Pay us a visit at your convenience.</h3>
      <p>Remember to make an appointment with us in advance.</p>
      <br></br>
      <h4 style={{color:'#222222'}}>Ślężna 148 street,<br></br> 55-010 <b>Radwanice</b><br></br></h4>
      <sub>The office is open from<b> 8 am to 4 pm</b>,<b> Monday to Friday.</b></sub>
      <br></br>
      <br></br>
      <br></br>
      <div>
      <Link align="center" className="btn" style={{zIndex:'10000'}} to="/">Return to the website </Link>
      </div>
      </div>


      </section>
      </Layout>
      </>
    )
  }

}


export const query = graphql`
  query FilesK{
    file(relativePath: {regex: "/optimized/zespol.jpg/"}) {
      childImageSharp {
        fluid(fit: COVER) {
        srcWebp
        src
        srcSet
        srcSetWebp
      }
      }
    }
  }
`
